.slide {
    text-align: center;
    padding: 20px;
  }
  
  .material-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .material-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .thumbnail {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .thumbnail:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
  
  .file-link {
    display: inline-block;
    padding: 12px 24px;
    background-color: #f0f0f0;
    border-radius: 8px;
    text-decoration: none;
    color: #333;
    font-weight: 600;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    margin-top: 10px;
  }
  
  .file-link:hover {
    background-color: #e0e0e0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
  
  .file-link:active {
    background-color: #d0d0d0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  