/* Modal container styling */
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

/* Modal image styling */
.modal-image {
  max-height: 90%;
  max-width: 90%;
  object-fit: contain;
  box-shadow: 0 0 10px white;
}
