.qr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center the entire content vertically */
  height: 100vh;
  padding: 10px 20px; /* Add some padding */
  text-align: center;
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 10px; /* Reduced margin below header */
  margin-top: 0; /* Remove top margin */
}

.slide {
  display: flex; /* Use flexbox */
  flex-direction: column; /* Stack content vertically */
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */
  width: 90%; /* Ensure the slide occupies enough width */
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  position: relative; /* Position relative for navigation arrows */
  flex-grow: 1; /* Allow slide content to take up available space */
  min-height: 60vh; /* Allow slides to occupy at least 60% of the viewport height */
}

.navigation {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.prev-button, .next-button {
  font-size: 3rem;
  padding: 10px 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease;
  position: absolute; /* Make sure the buttons are positioned absolutely */
}

.prev-button {
  left: 20px; /* Position the previous button on the left side */
}

.next-button {
  right: 20px; /* Position the next button on the right side */
}

.prev-button:hover, .next-button:hover {
  color: #000;
}
