.stats-container {
  padding: 20px;
  max-width: 1200px; /* Augmentez la largeur maximale pour plus d'espace */
  margin: auto;
}

h1 {
  text-align: center;
  color: #333;
}

.total-visits, .weekly-visits, .facilitator-totals {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.total-visits h2, .weekly-visits h2, .facilitator-totals h2 {
  margin-top: 0;
}

.big-number {
  font-size: 3rem;
  color: rgb(73, 64, 64);
  font-weight: bold;
  text-align: center;
}

.facilitator-totals {
  display: flex;
  flex-wrap: wrap; /* Permet de les envelopper à la ligne suivante */
  justify-content: space-between;
}

.facilitator-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  flex: 1 1 calc(33.333% - 20px); /* Trois cartes par ligne avec des marges */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  box-sizing: border-box;
}

.facilitator-card h3 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.facilitator-card p {
  margin: 10px 0 0;
  font-size: 1.2rem;
  color: #666;
}

.bar-chart-container {
  width: 100%;
  height: 400px;
}
