.option-container {
  display: flex;
  flex-direction: column; /* Stacks children vertically */
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Adjust this if you need vertical centering as well */
  gap: 15px; /* Adds space between children elements */
}

.option-container label {
  display: flex;
  padding: 10px;
  background-color: #f0f0f0; /* Grey background color */
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: background-color 1s ease;
  margin-bottom: 10px; /* Adds space below the label */
}

.option-container label:hover {
  background-color: #dcdcdc; /* Darker shade on hover */
}
